<template>
  <div class="he-r dynamic-margin" style="background: #ffffff!important;padding-bottom: 0!important;">
    <div class="dynamic-con">
      <h5 class="he-tit he-center-tit">
        <i class="tit-back" @click="back"></i>
        <span
          >{{ $route.params.friendName
          }}<i
            class="level-icon"
            :style="{ background: 'url(' + level + ') no-repeat center', backgroundSize: 'cover' }"
          ></i
        ></span>
      </h5>
      <div class="main-info-content">
        <div class="message-boxs">
          <template v-for="item in messageList">
            <p class="message-time" :key="item.id">{{ item.createTime | dateFormat('YYYY年MM月DD日 hh:mm') }}</p>
            <div v-if="$store.state.userId == item.memberId" class="message-r" :key="item.id">
              <div class="message-conten-pa">
                <div class="message-contents">{{ item.content }}</div>
              </div>
              <span
                class="message-person"
                :style="{
                  background: 'url(' + (item.memberAvatar || avatar) + ') no-repeat center',
                  backgroundSize: 'cover'
                }"
              ></span>
            </div>
            <div v-else class="message-l" :key="item.id">
              <span
                class="message-person"
                :style="{
                  background: 'url(' + (item.memberAvatar || avatar) + ') no-repeat center',
                  backgroundSize: 'cover'
                }"
              ></span>
              <div class="message-conten-pa">
                <div class="message-contents">{{ item.content }}</div>
              </div>
            </div>
          </template>
          <!-- <p><a class="more-links" href="#">查看更多聊天记录</a></p> -->
          <!-- <p class="message-time">2020年10月29日 10：35</p>
          <div class="message-l">
            <span
              class="message-person"
              :style="{ background: 'url(' + hImg1 + ') no-repeat center', backgroundSize: 'cover' }"
            ></span>
            <div class="message-conten-pa">
              <div class="message-contents">{{}}</div>
            </div>
          </div>
          <p class="message-time">0月29日 10：35</p>
          <div class="message-r">
            <div class="message-conten-pa">
              <div class="message-contents">{{}}</div>
            </div>
            <span
              class="message-person"
              :style="{ background: 'url(' + hImg2 + ') no-repeat center', backgroundSize: 'cover' }"
            ></span>
          </div> -->
        </div>
        <div class="sr-input-box">
          <textarea placeholder="请输入私信内容" v-model="message"></textarea>
          <div class="message-btn-box">
            <button type="button" class="el-button fs-btn" @click="sendMessage"><span>发布</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      avatar: require('../../assets/images/avatar.png'),
      messageList: [],
      message: '',
      mailImg: require('../../assets/images/icon3.png'),
      level: require('../../assets/images/level.png'),
      hImg1: require('../../assets/images/icon3.png'),
      hImg2: require('../../assets/images/icon5.png')
    }
  },
  mounted() {
    this.getMessageList()
  },
  methods: {
    getMessageList() {
      this.axios.get(`/api/portal/chat/auth/${this.$route.params.friendId}/page?current=1&size=999`).then(res => {
        this.messageList = res.data.records
      })
    },
    sendMessage() {
      if (this.message) {
        this.axios
          .post('/api//portal/chat/auth/save', {
            friendId: this.$route.params.friendId,
            imgUrls: '',
            content: this.message,
            fromClientType: 1
          })
          .then(res => {
			  this.message="";
            this.$message.success('发送成功')
            this.getMessageList()
          })
      }
    },
    back() {
      this.$router.push(`/messageTwo`)
    }
  }
}
</script>
<style lang="scss" scoped></style>
